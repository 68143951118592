@import 'app/styles/base.scss';

//style for mapbox
@import '~mapbox-gl/dist/mapbox-gl.css';

html,
body {
  margin: 0;
  font-family: $font-ubuntu;
  overflow: hidden;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
  font-size: $font-size-normal;
}

body{
  display: block;
}

html, button, input, textarea {
  font-family: $font-ubuntu;
  font-size: $font-size-normal;
}

span, div, textarea, input, button {
  &:focus {
    outline: none;
  }
}

.page-title {
  font-size: $font-size-xxbig;
  font-weight: $font-weight-bold;
  margin: $spacing-big 0 $spacing-small 0;
  display: inline-block;
}

.subtitle {
  color: black;
  font-weight: $font-weight-bold;
  margin: $spacing-small 0;
}

.container-title{
  color: $color-teal;
  font-size: $font-size-xbig;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-default;
}

.horizontal-divider {
  border-top: 1px solid $color-separator-gray;
  margin: $spacing-default 0;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $color-teal;
  }
}

* {
  box-sizing: border-box;
}
@media screen and (max-width: $mobile-big-width) {

  .container-title {
    margin-bottom: $spacing-small;
    font-size: 16px; // TODO
  }
}
